<template>
	<div data-route>
		<page-header
			heading="Create new focus"
		/>
		<div data-element="main">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="Course focus"
					rules="required"
					type="text"
					v-model="focus.focus"
				/>
				<actions
					:actions="getActions"
					@actionClick="onActionClick"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>

	import InputField          from '@/components/forms/InputField';
	import PageHeader          from '@/components/ui/PageHeader';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import api                 from '@/services/api';

	export default {
		metaInfo: {
			title: 'Create new focus'
		},
		components: {
			PageHeader,
			InputField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			focus: {
				focus: ''
			}
		}),
		computed: {
			getBreadcrumbRoute () {
				return '/admin/';
			},
			getActions () {
				return {
					secondary: [
						{
							text: 'Cancel',
							route: this.getBreadcrumbRoute
						},
						{
							text: 'Create focus',
							actionId: 'submit'
						}
					].filter(Boolean)
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/focuses',
						text: 'Focuses'
					},
					{
						path: '/admin/focuses/create',
						text: 'New focus'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const focus = await api.admin.createFocus({
					focus: this.focus
				});
				if (!focus) {
					return;
				}
				await this.$router.push(`/admin/focuses/${focus.id}`);
				this.$store.commit('ui/showNotification', {
					notification: 'The focus has been created'
				});
			}
		}
	};
</script>
